import React from "react"
import { graphql } from "gatsby"
import "../components/layout.scss"
import Layout from "../components/layout"
//import Seo from "../components/seo"
import Filter from "../components/filter"
import Helmet from "react-helmet"
import Div100vh from 'react-div-100vh'

const IndexPage = ({ data }) => {
  let projects = data?.allDirectusProject?.nodes
    ? data?.allDirectusProject?.nodes
    : []
  const removeBodyClass = () => {
    document.querySelector("body").removeClass("no-touch")
  }
  React.useEffect(() => {
    window.addEventListener("ontouchstart", removeBodyClass)
    return () => {
      window.removeEventListener("ontouchstart", removeBodyClass)
    }
  }, [])

  const getShuffledArr = arr => {
      const newArr = arr.slice()
      for (let i = newArr.length - 1; i > 0; i--) {
          const rand = Math.floor(Math.random() * (i + 1));
          [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
      }
      return newArr
  };

  if (projects?.length > 0) {
    const randomArr = getShuffledArr(projects)
    return (
      <Layout>
        <Helmet>
          <body className="no-touch" />
        </Helmet>
        <Div100vh className="projectsWrapper">
          <Filter projects={randomArr} />
        </Div100vh>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query Projects {
    allDirectusProject {
      nodes {
        id
        title
        category
        video
        publication_url
        files {
          description
          title
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                height: 760
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        video_image {
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                height: 760
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
